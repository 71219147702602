import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RedirectService, SharedAuthService, UserType } from '@my-tomorrows/shared-data';

export const crtMemberGuard: CanActivateFn = () => {
  const user = inject(SharedAuthService).$getUser();
  if (user.isCrtMember) {
    return true;
  }

  if (user.userType === UserType.search) {
    inject(RedirectService).navigate('login');
  } else {
    inject(Router).navigate(['/home']);
  }

  return false;
};
